<template>
  <div>
    <loading :active="progress.isDetailsLoading" :can-cancel="true"></loading>
    <md-steppers :md-active-step.sync="active" md-linear>
      <md-step
        id="first"
        md-label="Personal Details"
        :md-error="firstStepErr"
        :md-done.sync="first"
      >
        <first-step></first-step>
        <div class="text-center">
          <md-button class="btn-outline" @click="$router.back()"
            >Exit</md-button
          >
          <md-button
            class="md-raised md-primary"
            @click.native="nextStep('first', 'second')"
            >Next</md-button
          >
        </div>
      </md-step>
      <md-step id="second" md-label="Confirm" :md-done.sync="second">
        <second-step v-if="second"></second-step>
        <div class="text-center">
          <spinner
            :diameter="25"
            style="padding: 10px"
            v-if="progress.isSaving"
          ></spinner>
          <div v-else>
            <div class="button-container" v-if="manageAdmin.page == 'Approval'">
              <md-button
                class="btn-outline"
                @click="previosStep('first', 'first')"
                >Previous</md-button
              >
              <md-button
                class="md-raised md-primary"
                @click="handleAdminApprove(true)"
                >Approve</md-button
              >
              <md-button
                class="md-raised md-primary"
                @click="handleAdminApprove(false)"
                >Reject</md-button
              >
            </div>
            <div v-else class="button-container">
              <md-button
                class="btn-outline"
                @click="previosStep('first', 'first')"
                >Previous</md-button
              >
              <md-button class="md-raised md-primary" @click="handleAdminSave()"
                >Finish</md-button
              >
            </div>
          </div>
        </div>
      </md-step>
    </md-steppers>
  </div>
</template>
<script>
import FirstStep from "./1_PersonalDetails.vue";
import SecondStep from "./2_ConfirmRegister.vue";
export default {
  components: {
    FirstStep,
    SecondStep,
  },
  inject: [
    "isValidForm",
    "handleAdminApprove",
    "handleAdminSave",
    "manageAdmin",
    "progress",
  ],
  data() {
    return {
      firstStepErr: null,
      secondStepErr: null,
      thirdStepErr: null,
      active: "first",
      first: false,
      second: false,
    };
  },
  methods: {
    async nextStep(current, next) {
      if (!(await this.isValidForm())) {
        return;
      }
      this.moveToNextStep(current, next);
    },
    moveToNextStep(current, next) {
      this[current] = true;
      this.StepError = null;
      if (next) {
        this.active = next;
        this.second = true;
        this.first = false;
      }
    },
    previosStep(id, index) {
      this[id] = true;
      if (index) {
        this.active = index;
        this.second = false;
        this.first = true;
      }
    },
    exit() {
      this.$router.push("/reading-center");
    },
    finish() {
      this.registerUser();
    },
  },
};
</script>
<style scoped>
.text-center {
  padding-top: 15px;
}
</style>
