<template>
  <div>
    <div class="md-layout md-gutter">
      <div class="md-layout-item md-small-hide">
        <img
          class="banner-img"
          src="@/assets/img/slide1.jpg"
          alt="Globe Check"
        />
      </div>
      <div
        class="md-layout-item md-size-75 md-medium-size-75 md-small-size-100"
      >
        <md-card class="card-rounded p-2">
          <div class="d-flex justify-content-center align-item-center mb-4">
            <img
              class="reg-logo"
              src="@/assets/img/GlobeChek.png"
              alt="Globe Check Logo"
            />
            <p class="md-subheading m-0 ml-3">
              <b>{{ title }}</b>
            </p>
          </div>
          <doctor-form
            v-if="page == 'doctor' || page == 'reader'"
          ></doctor-form>
          <patient-form v-if="page == 'patient'"></patient-form>
          <AdminForm v-if="page == 'admin'"></AdminForm>
          <TechnicianForm v-if="page == 'technician'"></TechnicianForm>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import DoctorForm from "../doctor/manage-doctor/DoctorForm.vue";
import { withManageDoctorHandler } from "../../handlers/module-handlers/doctor/ManageDoctorHandler";
import PatientForm from "../patient/manage-patient/ManagePatientPage.vue";
import { withManagePatientHandler } from "../../handlers/module-handlers/patient/ManagePatientHandler";
import { withManageTechnicianHandler } from "../../handlers/module-handlers/technician/ManageTechnicianHandler";
import { withManageAdminHandler } from "../../handlers/module-handlers/admin/ManageAdminHandler";
import AdminForm from "../Admin/manage-Admin/ManageAdminPage.vue";
import TechnicianForm from "../Technician/manage-technician/ManageTechnicianPage.vue";

export default {
  components: {
    DoctorForm: withManageDoctorHandler(DoctorForm),
    PatientForm: withManagePatientHandler(PatientForm),
    AdminForm: withManageAdminHandler(AdminForm),
    TechnicianForm: withManageTechnicianHandler(TechnicianForm),
  },
  data() {
    return {
      page: this.$route.params.userType,
      title: "",
    };
  },
  created() {
    this.gettitle();
  },
  methods: {
    isForm(type) {
      return this.page == type;
    },
    gettitle() {
      if (this.$route.params.userType == "admin") {
        this.title = "Admin Registration";
      } else if (this.$route.params.userType == "technician") {
        this.title = "Technician Registration";
      } else if (this.$route.params.userType == "doctor") {
        this.title = "Doctor Registration";
      } else if (this.$route.params.userType == "reader") {
        this.title = "Reader Registration";
      } else if (this.$route.params.userType == "patient") {
        this.title = "Patient Registration";
      }
    },
  },
  provide() {
    return {
      refreshList: () => {},
    };
  },
};
</script>
