<template>
  <ValidationListener
    @getIsValid="(isValid) => (UserForm.isPersonalDataValid = isValid)"
  >
    <div>
      <div class="d-flex justify-content-center">
        <profile-image
          defaultSrc="technician"
          :options="{
            feature: 'User',
            featureId: UserForm.postData.userID,
            category: 'PROFILE',
          }"
          @getFileUploadRef="(ref) => (UserForm.fileUploadRef = ref)"
        ></profile-image>
      </div>
      <div class="md-layout">
        <div
          class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100"
        >
          <div class="d-flex mr-2">
            <label class="labeltext mr-2">Title</label>
            <span style="font-weight: bold">{{ title }}</span>
          </div>
          <ValidationHandler
            :name="UserForm.postData.person.firstName"
            rules="required|alpha_spaces"
            v-slot="{ error }"
          >
            <TextInput
              label="First Name *"
              placeholder="First Name *"
              icon="perm_identity"
              :required="true"
              v-model="UserForm.postData.person.firstName"
              :error="error"
              icons="perm_identity"
            />
          </ValidationHandler>
          <ValidationHandler
            :name="UserForm.postData.person.lastName"
            rules="required|alpha_spaces|min:2"
            v-slot="{ error }"
          >
            <TextInput
              type="text"
              label="Last Name *"
              placeholder="Last Name *"
              icon="perm_identity"
              :required="true"
              v-model="UserForm.postData.person.lastName"
              :error="error"
              icons="record_voice_over"
            />
          </ValidationHandler>
          <div>
            <ValidationHandler
              :name="UserForm.postData.person.gender"
              rules="required"
              v-slot="{ error }"
            >
              <label class="labeltext">Gender *</label>
              <div class="mb-3 d-flex" style="margin-bottom: 0">
                <radio-button-input
                  name="gender"
                  :options="
                    master.genderList.map((x) => ({
                      value: x.value,
                      text: x.displayValue,
                    }))
                  "
                  v-model="UserForm.postData.person.gender"
                  :error="error"
                />
              </div>
            </ValidationHandler>
          </div>
          <div
            class="w-100"
            v-if="
              checkPermission(Permissions.SuperAdmin) &&
              (manageTechnician.id || manageTechnician.page != 'Register')
            "
          >
            <ValidationHandler
              :name="UserForm.postData.orgID"
              rules="required"
              v-slot="{ error }"
            >
              <div class="text-field">
                <select-input
                  name="organisation"
                  displayName="Organisation *"
                  placeHolder="Organisation *"
                  :options="
                    master.organisationList.map((x) => ({
                      value: x.clientID,
                      text: x.clientName,
                    }))
                  "
                  v-model="UserForm.postData.orgID"
                  :required="true"
                  :error="error"
                />
              </div>
            </ValidationHandler>
          </div>
        </div>
        <div
          class="md-layout-item md-size-50 md-medium-size-50 md-small-size-100"
        >
          <label class="labeltext">Contact Details</label>
          <ValidationHandler
            :name="UserForm.postData.person.email"
            rules="required|email"
            v-slot="{ error }"
          >
            <TextInput
              type="text"
              label="Email *"
              placeholder="Email *"
              icon="mail_outline"
              :required="false"
              v-model="UserForm.postData.person.email"
              :error="error"
              icons="email"
            />
          </ValidationHandler>
          <div class="d-flex">
            <div class="md-layout-item p-0">
              <ValidationHandler
                :name="UserForm.postData.person.cellNumber"
                rules="required|min:14|max:18"
                v-slot="{ error }"
              >
                <TextInput
                  type="text"
                  label="Phone Number *"
                  placeholder="Phone Number *"
                  icon="settings_cell"
                  :required="true"
                  v-model="UserForm.postData.person.cellNumber"
                  v-mask="'(###) ###-########'"
                  :error="error"
                  icons="smartphone"
                />
              </ValidationHandler>
            </div>
          </div>
          <ValidationHandler
            :name="UserForm.postData.address.address1"
            rules="required"
            v-slot="{ error }"
          >
            <TextInput
              type="text"
              label="Mailing Address *"
              placeholder="Mailing Address *"
              icon="place"
              :required="true"
              v-model="UserForm.postData.address.address1"
              :error="error"
              icons="room"
            />
          </ValidationHandler>
          <div class="d-flex">
            <div class="w-100" style="height: 65px">
              <ValidationHandler
                :name="UserForm.postData.address.city"
                rules="required|alpha_spaces"
                v-slot="{ error }"
              >
                <TextInput
                  type="text"
                  label="City *"
                  placeholder="City *"
                  icon="location_city"
                  :required="true"
                  v-model="UserForm.postData.address.city"
                  :error="error"
                  icons="domain"
                />
              </ValidationHandler>
            </div>
          </div>
          <div class="w-100" style="height: 65px">
            <ValidationHandler
              :name="UserForm.postData.address.state"
              rules="required"
              v-slot="{ error }"
            >
              <div class="text-field">
                <select-input
                  name="state"
                  displayName="State *"
                  placeHolder="state *"
                  :options="
                    master.stateList.map((x) => ({
                      value: x.name,
                      text: x.displayValue,
                    }))
                  "
                  v-model="UserForm.postData.address.state"
                  :required="true"
                  :error="error"
                />
              </div>
            </ValidationHandler>
          </div>

          <div class="w-100 mr-1" style="height: 65px">
            <ValidationHandler
              :name="UserForm.postData.address.country"
              rules="required"
              v-slot="{ error }"
            >
              <div class="text-field">
                <select-input
                  name="country"
                  displayName="Country *"
                  placeHolder="country"
                  icon="outlined_flag"
                  :options="
                    master.countryCodeList.map((x) => ({
                      value: x.name,
                      text: x.displayValue,
                    }))
                  "
                  v-model="UserForm.postData.address.country"
                  :required="true"
                  :error="error"
                />
              </div>
            </ValidationHandler>
          </div>
          <div class="w-100">
            <ValidationHandler
              :name="UserForm.postData.address.zipCode"
              rules="required|min:5|max:16"
              v-slot="{ error }"
            >
              <TextInput
                type="text"
                label="Zip Code *"
                placeholder="Zip Code *"
                :required="true"
                v-mask="'#####-#####-####'"
                v-model="UserForm.postData.address.zipCode"
                :error="error"
                icon="pin"
              />
            </ValidationHandler>
          </div>
        </div>
      </div>
    </div>
  </ValidationListener>
</template>
<script>
import TextInput from "../../../components/controls/TextInput.vue";
import RadioButtonInput from "../../../components/controls/RadioButtonInput.vue";
//import AdminImage from "../../../components/Globechek/Register/profileimgupload/Adminprofileimg.vue";
import ProfileImage from "../../../components/Globechek/Register/ProfileImage.vue";

export default {
  inject: [
    "UserForm",
    "master",
    "manageTechnician",
    "checkPermission",
    "Permissions",
    "navigationBlocker",
  ],
  components: {
    TextInput,
    RadioButtonInput,
    ProfileImage,
  },
  data() {
    return {
      title: "Technician",
      technician: {
        firstName: 0,
        lastName: 0,
        gender: 0,
        email: 0,
        cellNumber: 0,
        address1: 0,
        city: 0,
        state: 0,
        country: 0,
        zipCode: 0,
        orgID: 0,
      },
    };
  },
  created() {
    this.getthetitle();
  },
  methods: {
    getthetitle() {
      if (this.$route.params.userType == "technician") {
        this.title = "Technician";
      }
    },
  },
  watch: {
    "UserForm.postData.person.firstName"() {
      this.technician.firstName = this.technician.firstName + 1;
      if (this.$route.path.includes("edit") && this.technician.firstName > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("register/technician")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "UserForm.postData.person.lastName"() {
      this.technician.lastName = this.technician.lastName + 1;
      if (this.$route.path.includes("edit") && this.technician.lastName > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("register/technician")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "UserForm.postData.person.gender"() {
      this.technician.gender = this.technician.gender + 1;
      if (this.$route.path.includes("edit") && this.technician.gender > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("register/technician")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "UserForm.postData.person.email"() {
      this.technician.email = this.technician.email + 1;
      if (this.$route.path.includes("edit") && this.technician.email > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("register/technician")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "UserForm.postData.person.cellNumber"() {
      this.technician.cellNumber = this.technician.cellNumber + 1;
      if (this.$route.path.includes("edit") && this.technician.cellNumber > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("register/technician")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "UserForm.postData.address.address1"() {
      this.technician.address1 = this.technician.address1 + 1;
      if (this.$route.path.includes("edit") && this.technician.address1 > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("register/technician")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "UserForm.postData.address.city"() {
      this.technician.city = this.technician.city + 1;
      if (this.$route.path.includes("edit") && this.technician.city > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("register/technician")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "UserForm.postData.address.state"() {
      this.technician.state = this.technician.state + 1;
      if (this.$route.path.includes("edit") && this.technician.state > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("register/technician")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "UserForm.postData.address.country"() {
      this.technician.country = this.technician.country + 1;
      if (this.$route.path.includes("edit") && this.technician.country > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("register/technician")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "UserForm.postData.address.zipCode"() {
      this.technician.zipCode = this.technician.zipCode + 1;
      if (this.$route.path.includes("edit") && this.technician.zipCode > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("register/technician")
      ) {
        this.navigationBlocker.val = false;
      }
    },
    "UserForm.postData.orgID"() {
      this.technician.orgID = this.technician.orgID + 1;
      if (this.$route.path.includes("edit") && this.technician.orgID > 1)
        this.navigationBlocker.val = false;
      else if (
        this.$route.path.includes("add") |
        this.$route.path.includes("register/technician")
      ) {
        this.navigationBlocker.val = false;
      }
    },
  },
};
</script>
